export function FerryKinectDispatchApp(opts) {

    let self = this;

    const STATUS_DISABLED = 0;
    const STATUS_ENABLED = 1;

    let defaults = {
        login_check_interval: 3,
        session_check_interval: 60,
        dispatch_query_interval: 60,
        gps_tracker_frame_id: "gps_tracker_frame",
        gps_tracker_login_id: "login-1009-bodyWrap",
        gps_tracker_loggedin_selector: "html.x-viewport",
        dispatch_queue_tray_id: "dispatch_queue_tray",
        dispatch_queue_heading_id: "dispatch_queue_heading",
        dispatch_overlay_id: "dispatch_overlay",
        gps_tracker_frame_src: "",
        app_selector: "#ferrykinect",
        api_endpoint: "",
        alert_sound: "",
        notification_image: null,
    }

    opts = {...defaults, ...(opts || {})}

    this.login_check_interval = opts.login_check_interval;
    this.session_check_interval = opts.session_check_interval;
    this.dispatch_query_interval = opts.dispatch_query_interval;
    this.gps_tracker_frame_id = opts.gps_tracker_frame_id;
    this.gps_tracker_frame_src = opts.gps_tracker_frame_src;
    this.dispatch_queue_tray_id = opts.dispatch_queue_tray_id;
    this.dispatch_queue_heading_id = opts.dispatch_queue_heading_id;
    this.dispatch_overlay_id = opts.dispatch_overlay_id;
    this.app_selector = opts.app_selector;
    this.api_endpoint = opts.api_endpoint;
    this.alert_sound = opts.alert_sound;
    this.notification_image = opts.notification_image;

    //Elements in GPS frame
    this.gps_tracker_login_id = opts.gps_tracker_login_id;
    this.gps_tracker_loggedin_selector = opts.gps_tracker_loggedin_selector;

    this.session = { valid: false, id: "" };
    this.login_interval_id = 0;
    this.session_interval_id = 0;
    this.dispatch_query_interval_id = 0;
    this.gps_frame_document = null;
    this.gps_frame_element = null;
    this.frame_loaded = false;
    this.status = STATUS_DISABLED;
    this.overlay_visible = false;
    this.app_element = null;
    this.audio_element = null;
    this.can_browser_notification = false;
    this.system_status = 0;

    this.dispatch_queue_tray_element = null;
    this.dispatch_queue_tray_header_element = null;
    this.dispatch_settings_tray_element = null;
    this.dispatch_queue_heading = null;
    this.dispatch_ferryname_heading = null;
    this.dispatch_system_status_heading = null;
    this.dispatch_overlay_element = null;
    this.settings_overlay_element = null;
    this.dispatch_overlay_title_element = null;
    this.dispatch_overlay_container_element = null;
    this.dispatch_assignment_form_element = null;
    this.dispatch_public_name_form_element = null;
    this.system_status_form_element = null;
    //this.queue_items = [];
    this.current_queueitem_reference = "";
    this.customer_settings = null;

    this.init = function() {

        self.getNotification();

        self.setStatus(STATUS_DISABLED);

        self.renderFrame();

        $('.popover').popover('dispose');
        $('.popover').remove();

        self.login_interval_id = setInterval(function(){

            self.checkForLogin();

        }, self.login_check_interval * 1000);

        self.session_interval_id = setInterval(function(){

            self.checkSession();
            self.querySystemStatus();

            }, self.session_check_interval * 1000);

        self.dispatch_query_interval_id = setInterval( function() {

            self.queryDispatchQueue();

        }, self.dispatch_query_interval * 1000);
    }

    this.renderFrame = function () {
        self.app_element = $(self.app_selector);

        self.gps_frame_element = $("<iframe id=\""+self.gps_tracker_frame_id+"\" src=\"\"></iframe>");
        self.gps_frame_element.appendTo(self.app_element);

        self.setFrame();
    }

    this.render = function() {
        if($(self.app_selector)==null) {
            $("body").append("<div id='"+self.app_selector+"'></div>")
        }

        let dispatch_form = "<div class=\"modal fade\" id=\"dispatch_assignment_modal\" role=\"dialog\" style=\"opacity:1;\">\n" +
            "    <div class=\"modal-dialog modal-dialog-centered\">\n" +
            "      <div class=\"modal-content\">\n" +
            "        <div class=\"modal-header\">\n" +
            "          <h4 class=\"modal-title\">Modal Header</h4>\n" +
            "          <button type=\"button\" class=\"close\" data-dismiss=\"modal\">&times;</button>\n" +
            "        </div>\n" +
            "        <div class=\"modal-body\">\n" +
            "          <div class='modal-body-text'></div>\n" +
            "               <hr>\n" +
            "           <div class=\"container-fluid\">\n" +
            "           <div class=\"row\" >\n" +
            "               <div class=\"col-md-6\">\n" +
            "                   <select id=\"device_list\" class=\"device_list form-control form-control-sm\" title='Choose Vehicle'></select> \n" +
            "               </div>\n" +
            "               <div class=\"col-md-4\">\n" +
            "                   <select id=\"eta_list\" class=\"eta_list form-control form-control-sm\" title='ETA'></select> \n" +
            "               </div>\n" +
            "               <div class=\"col-md-2\">\n" +
            "                   <button type=\"button\" class=\"btn assign-queueitem\" title='Assign'><i class=\"bi bi-signpost\"></i></button>\n" +
            "                   <button type=\"button\" class=\"btn reassign-queueitem\" title='Reassign'><i class=\"bi bi-signpost-2\"></i></button>\n" +
            "               </div>\n" +
            "           </div>\n" +
            "        </div>\n" +
            "        <div class=\"modal-footer\">\n" +
            "           <div class=\"container-fluid\">\n" +
            "           <div class=\"row\">\n" +
            "               <div class=\"col-md-8\">\n" +
            "               </div>\n" +
            "               <div class=\"col-md-4\">\n";

            if(!self.customer_settings.settings.can_reassign) {
                dispatch_form += "                   <button type=\"button\" class=\"btn delete-queueitem\" title='Delete'><i class=\"bi bi-folder-x\"></i></button>\n";
            }
            else {
                dispatch_form += "                   <button type=\"button\" class=\"btn remove-queueitem\" title='Remove'><i class=\"bi bi-folder-x\"></i></button>\n";
            }


            dispatch_form += "                   <button type=\"button\" class=\"btn btn-default btn-close\" data-dismiss=\"modal\" title='Close'><i class=\"bi bi-x-square\"></i></button>\n" +
            "               </div>\n" +
            "           </div>\n" +
            "        </div>\n" +
            "        </div>\n" +
            "      </div>\n" +
            "    </div>\n" +
            "  </div>";

        self.dispatch_assignment_form_element = $(dispatch_form);
        self.dispatch_assignment_form_element.appendTo($("body"));

        let public_name_form = "<div class=\"modal fade\" id=\"dispatch_public_name_modal\" role=\"dialog\" style=\"opacity:1;\">\n" +
            "    <div class=\"modal-dialog modal-dialog-centered\">\n" +
            "      <div class=\"modal-content\">\n" +
            "        <div class=\"modal-header\">\n" +
            "          <h4>Public Names</h4>\n" +
            "          <button type=\"button\" class=\"close\" data-dismiss=\"modal\">&times;</button>\n" +
            "        </div>\n" +
            "        <div class=\"modal-body\">\n" +
            "           <div class=\"container-fluid\">\n" +
            "           <div class=\"row\" >\n" +
            "               <div class=\"col-md-6\">\n" +
            "                   <label for='device_rename_list'>Dispatch Name</label>\n" +
            "               </div>\n" +
            "               <div class=\"col-md-6\">\n" +
            "                   <label for='device_rename_text'>Public Name</label>\n" +
            "               </div>\n" +
            "           </div>\n" +
            "           <div class=\"row\" >\n" +
            "               <div class=\"col-md-6\">\n" +
            "                   <select id=\"device_rename_list\" name=\"device_rename_list\" class=\"device_list form-control form-control-sm\" title='Choose Vehicle'></select> \n" +
            "               </div>\n" +
            "               <div class=\"col-md-6\">" +
            "                   <input type='text' id='device_rename_text'  name='device_rename_text' class='form-control form-control-sm' title='Enter a name' />" +
            "               </div>\n" +
            "           </div>\n" +
            "           <div class=\"row\" >\n" +
            "               <div class=\"col-md-12 device_rename_action\">\n" +
            "                   <button type=\"button\" class=\"btn rename_device\" title='Rename'><i class=\"bi bi-input-cursor-text\"></i></button>\n" +
            "               </div>\n" +
            "           </div>\n" +
            "           </div>\n" +
            "           </div>\n" +
            "        <div class=\"modal-footer\">\n" +
            "           <div class=\"container-fluid\">\n" +
            "           <div class=\"row\">\n" +
            "               <div class=\"col-md-8\">\n" +
            "               </div>\n" +
            "               <div class=\"col-md-4\">\n" +
            "                   <button type=\"button\" class=\"btn btn-default btn-close\" data-dismiss=\"modal\" title='Close'><i class=\"bi bi-x-square\"></i></button>\n" +
            "               </div>\n" +
            "           </div>\n" +
            "        </div>\n" +
            "        </div>\n" +
            "      </div>\n" +
            "    </div>\n" +
            "  </div>";

        self.dispatch_public_name_form_element = $(public_name_form);
        self.dispatch_public_name_form_element.appendTo($("body"));

        let system_status_form = "<div class=\"modal fade\" id=\"system_status_modal\" role=\"dialog\" style=\"opacity:1;\">\n" +
            "    <div class=\"modal-dialog modal-dialog-centered\">\n" +
            "      <div class=\"modal-content\">\n" +
            "        <div class=\"modal-header\">\n" +
            "          <h4>System Status</h4>\n" +
            "          <button type=\"button\" class=\"close\" data-dismiss=\"modal\">&times;</button>\n" +
            "        </div>\n" +
            "        <div class=\"modal-body\">\n" +
            "           <div class=\"container-fluid\">\n" +
            "           <div class=\"row\" >\n" +
            "               <div class=\"col-md-12\">\n" +
            "                   <select id=\"system_status_list\" class=\"status_list form-control form-control-sm\" title='System Status'><option value='0'>Off</option><option value='1'>On</option></select> \n" +
            "               </div>\n" +
            "           </div>\n" +
            "           <div class=\"row\" >\n" +
            "               <div class=\"col-md-12 system_status_action\">\n" +
            "                   <button type=\"button\" class=\"btn set_system_status\" title='Set Status'><i class=\"bi bi-lightning\"></i></button>\n" +
            "               </div>\n" +
            "           </div>\n" +
            "           </div>\n" +
            "           </div>\n" +
            "        <div class=\"modal-footer\">\n" +
            "           <div class=\"container-fluid\">\n" +
            "           <div class=\"row\">\n" +
            "               <div class=\"col-md-8\">\n" +
            "               </div>\n" +
            "               <div class=\"col-md-4\">\n" +
            "                   <button type=\"button\" class=\"btn btn-default btn-close\" data-dismiss=\"modal\" title='Close'><i class=\"bi bi-x-square\"></i></button>\n" +
            "               </div>\n" +
            "           </div>\n" +
            "        </div>\n" +
            "        </div>\n" +
            "      </div>\n" +
            "    </div>\n" +
            "  </div>";

        self.system_status_form_element = $(system_status_form);
        self.system_status_form_element.appendTo($("body"));


        $('.set_system_status').off('click').on("click", function() {
            let stat = $("#system_status_list").val();
            self.updateSystemStatus(stat);
            self.system_status_form_element.modal('hide');

        });

        $('#device_rename_list').on("change", function() {
            self.setPublicNameTextbox();
        });

        $('.rename_device').off('click').on("click", function() {
            //TODO: Rename
            self.renameDevice($("#device_rename_list").val(), $("#device_rename_text").val())
        });

        $(".delete-queueitem").off('click').on("click", function() {
            if(confirm("Are you sure you want to delete this item?")) {
                self.deleteDispatchQueueItem(self.current_queueitem_reference);
            }
        });

        $(".remove-queueitem").off('click').on("click", function() {
            if(confirm("Are you sure you want to remove this item?")) {
                self.removeDispatchQueueItem(self.current_queueitem_reference);
            }
        });

        $(".assign-queueitem").off('click').on("click", function() {
            self.assignDevice(self.current_queueitem_reference, $('#device_list').val(), $('#eta_list').val());
            $('#dispatch_assignment_modal').modal('hide');
            self.queryDispatchQueue();
        });

        $(".reassign-queueitem").off('click').on("click", function() {
            self.assignDevice(self.current_queueitem_reference, $('#device_list').val(), $('#eta_list').val());
            $('#dispatch_assignment_modal').modal('hide');
            self.queryDispatchQueue();
        });

        self.dispatch_queue_tray_element = $("<div id=\""+self.dispatch_queue_tray_id+"\"></div>");
        self.dispatch_queue_tray_element.appendTo(self.app_element);

        self.dispatch_queue_heading = $("<div id=\""+self.dispatch_queue_heading_id+"\">Dispatch Queue <span class=\"dispatch_dot off\"></span></div>");
        self.dispatch_queue_heading.appendTo(self.dispatch_queue_tray_element);

        self.dispatch_settings_tray_element = $("<div id='settings_tray'><button type=\"button\" class=\"btn btn-default open_settings\" title=\"\" data-original-title=\"Settings\"><i class=\"bi bi-wrench\"></i><span class=\"settings_dot\"></span></button></div>");
        self.dispatch_settings_tray_element.appendTo(self.dispatch_queue_tray_element);

        self.settings_overlay_element = $("<div id=\"settings_overlay\"><div class=\"settings_overlay_title\">Settings <span class=\"settings_dot\"></span> <button type=\"button\" class=\"close\">×</button></div></div>");
        self.settings_overlay_element.appendTo(self.app_element);

        self.dispatch_ferryname_heading = $("<div id=\"dispatch_ferryname_heading\" class=\"setting_item\">Public Names</div>");
        self.dispatch_ferryname_heading.appendTo(self.settings_overlay_element);

        self.dispatch_system_status_heading = $("<div id=\"dispatch_system_status_heading\" class=\"setting_item\">System Status</div>");
        self.dispatch_system_status_heading.appendTo(self.settings_overlay_element);

        self.dispatch_settings_tray_element.off('click').on("click", function() {
            self.settings_overlay_element.slideToggle();
        })

        self.dispatch_system_status_heading.off('click').on('click', function() {
            self.system_status_form_element.modal();
        })

        self.dispatch_overlay_element = $("<div id=\"dispatch_overlay\"></div>");
        self.dispatch_overlay_element.appendTo(self.app_element);

        self.dispatch_overlay_title_element = $("<div class=\"dispatch_overlay_title\">Dispatch Queue <span class=\"dispatch_dot off\"></span> <button type=\"button\" class=\"close\">×</button></div>\n");
        self.dispatch_overlay_title_element.appendTo(self.dispatch_overlay_element);

        self.dispatch_overlay_container_element = $("<div class=\"dispatch_overlay_container\"></div>\n");
        self.dispatch_overlay_container_element.appendTo(self.dispatch_overlay_element);

        let alertContainer = "<div id='dispatch_alerts'></div>";
        $(alertContainer).appendTo($("body"));

        $(".settings_overlay_title").off('click').on("click", function() {
            self.settings_overlay_element.slideToggle();
        });

        self.dispatch_overlay_title_element.off('click').on("click", function() {
            if(!self.overlay_visible) {
                self.dispatch_overlay_element.slideUp();
                self.overlay_visible = true;
            }
            else {
                self.dispatch_overlay_element.slideDown();
                self.overlay_visible = false;
            }

        });

        self.dispatch_queue_heading.off('click').on("click", function() {
            ///if(self.overlay_visible) {
            if(!self.overlay_visible) {
                self.dispatch_overlay_element.slideUp();
                self.overlay_visible = true;
            }
            else {
                self.dispatch_overlay_element.slideDown();
                self.overlay_visible = false;
            }

        });
        self.dispatch_queue_heading.click();

        self.dispatch_ferryname_heading.off('click').on("click", function() {
            self.queryDevices();
            //self.setPublicNameTextbox();
            $("#dispatch_public_name_modal").modal();
        });

        self.dispatch_system_status_heading.off('click').on("click", function() {
            self.querySystemStatus();
            $("#system_status_modal").modal();
        });


        if(self.alert_sound) {
            self.audio_element = new Audio(self.alert_sound);
        }
    }

    this.setFrame = function() {

        self.gps_frame_element.on("load", function() {
            self.gps_frame_document = document.getElementById(self.gps_tracker_frame_id).contentWindow.document;
            self.frame_loaded = true;
        });

        self.gps_frame_element.on("error", function() {
            self.gps_frame_document = null;
            self.frame_loaded = false;
            self.setStatus(STATUS_DISABLED);
        });

        self.gps_frame_element.attr("src", self.gps_tracker_frame_src);
    }

    this.getQueueItemClass = function(queueitem) {
        return queueitem.state===1?"qi_new":"qi_assigned";
    }

    this.setPublicNameTextbox = function() {
        let pubname = $('#device_rename_list option:selected').attr('data-public-name');
        $('#device_rename_text').val(pubname);
    }

    this.renderQueue = function(queue) {
        console.log("renderQueue");
        // self.dispatch_overlay_container_element.empty();
        $(".dispatch_dot").removeClass("on");
        $(".dispatch_dot").removeClass("off");
        let dotclass = queue.length>0?"on":"off";
        $(".dispatch_overlay_queue_item").addClass("old");
        let bAlert = false;
        self.queue_items = [];

        //$(".dispatch_overlay_queue_item").remove();
        for(let i =0;i < queue.length; i++) {
            self.queue_items.push(self.renderQueueItem(queue[i]))

            if(!$(".qi_"+queue[i].reference).length > 0) {
                //$(".qi_"+queue[i].reference).remove();
                bAlert = true;
            }
            // let qi = self.renderQueueItem(queue[i]);
            // $(qi).appendTo(self.dispatch_overlay_container_element);
            /*
            if($(".qi_"+queue[i].reference).length > 0) {
                $(".qi_"+queue[i].reference).removeClass("old").removeClass("qi_new").removeClass("qi_assigned");
                $(".qi_"+queue[i].reference).addClass(self.getQueueItemClass(queue[i]));
                self.queue_items.push($(".qi_"+queue[i].reference));
            }
            else {
                let qi = self.renderQueueItem(queue[i]);
                $(qi).appendTo(self.dispatch_overlay_container_element);
                //Should also show popup
                //this.browserNotification(queue[i]);
                bAlert = true;
            }
             */
        }
        $(".dispatch_overlay_queue_item").remove();
        for(let i=0;i<self.queue_items.length;i++) {
            $(self.queue_items[i]).appendTo(self.dispatch_overlay_container_element);
        }

        $(function () {
            /*
            $('[data-toggle="popover"]').popover({
                trigger: "hover"
            });

             */
        })

        $(".dispatch_overlay_queue_item .open_request").off('click').on("click", function(item) {
            self.current_queueitem_reference = $(item.currentTarget).data("reference");
            self.getDispatchQueueItem($(item.currentTarget).data("reference"))

        });

        $(".dispatch_overlay_queue_item .remove_request").off('click').on("click", function(item) {
            self.current_queueitem_reference = $(item.currentTarget).data("reference");
            if(confirm("Are you sure you want to remove this request?")) {
                self.removeDispatchQueueItem(self.current_queueitem_reference);
            }
        });

        //Remove items not in list
        //$(".dispatch_overlay_queue_item.old").remove();
        $(".dispatch_dot").addClass(dotclass);
        if(bAlert) {
            self.browserNotification("There are new items in the queue.");
            self.playNotification();
        }
    }

    this.renderDevices = function(data) {
        $(".device_list").empty();
        for(let i=0;i<data.length;i++) {
            //if(data.status==="online") {
            let pubname = data[i].attributes.hasOwnProperty("publicname")?data[i].attributes.publicname:data[i].name;
            let fname = data[i].attributes.hasOwnProperty("publicname")?"("+data[i].attributes.publicname+")":"";
            let dev = "<option value='"+data[i].id+"' class='status_"+data[0].status+"' data-public-name='"+pubname+"'>" + data[i].name + " " + fname + "</option>";

            $(dev).appendTo($(".device_list"));
                /*
            if(data.status==="online") {
                $(dev).appendTo($(".active_device_list"));
                $(dev).appendTo($(".all_device_list"));
            }
            else {
                $(dev).appendTo($(".all_device_list"));
            }

                 */
            //}
        }
        self.setPublicNameTextbox();
    }

    this.playNotification = function() {
        if(self.audio_element!=null) {
            self.audio_element.play();
        }
    }

    this.browserNotification = function(text) {
        if(!this.can_browser_notification) { return; }
        //TODO: image
        //var img = '/to-do-notifications/img/icon-128.png';
        /* let text = "Pickup for "+queueitem.num_total +" requested at " + queueitem.location_name +
        " headed to " + queueitem.destination_name; */
        //var notification = new Notification('To do list', { body: text, icon: img });
        let notification;
        if(self.notification_image) {
            let img = self.notification_image;
            notification = new Notification('FerryKinect', { body: text, icon: img });
        }
        else {
            notification = new Notification('FerryKinect', { body: text });
        }
        notification.onclick = function(event) {
            self.dispatch_queue_heading.click();
        }
    }

    this.handlePermission = function(result) {
        if(Notification.permission === 'denied' || Notification.permission === 'default') {
            self.can_browser_notification = false;
        } else {
            self.can_browser_notification = true;
        }
    }

    this.checkNotificationPromise = function() {
        try {
            Notification.requestPermission().then();
        } catch(e) {
            return false;
        }

        return true;
    }

    this.getNotification = function() {
        if (!('Notification' in window)) {
            console.log("This browser does not support notifications.");
        } else {
            if(self.checkNotificationPromise()) {
                Notification.requestPermission()
                    .then((permission) => {
                        self.handlePermission(permission);
                    })
            } else {
                Notification.requestPermission(function(permission) {
                    self.handlePermission(permission);
                });
            }
        }
    }

    this.renderQueueItem = function(queueitem) {
        //console.log("renderQueueItem");
        var utcDate = queueitem.created_at;
        var localDate = new Date(utcDate);
        var itemClass = self.getQueueItemClass(queueitem);
        let ret = "<div class=\"dispatch_overlay_queue_item qi_"+queueitem.reference+" "+itemClass+"\">\n" +
            "<p class='dispatch_queueitem_date'>"+localDate.toLocaleString()+"</p>" +
            "<p class='dispatch_queueitem_ref'>Reference: "+queueitem.reference+"</p>" +
            "<span class=\"buttons\"><button type=\"button\" class=\"btn btn-default open_request\" data-reference=\""+queueitem.reference+"\" title='Open' ><i class=\"bi bi-folder-symlink\" aria-hidden=\"true\"></i></button>";
        if(self.customer_settings.settings.can_reassign) {
            ret += "<button type=\"button\" class=\"btn btn-default remove_request\" data-reference=\"" + queueitem.reference + "\" title='Remove'><i class=\"bi bi-folder-x\" aria-hidden=\"true\"></i></button>";
        }
        ret += "</span></span>\n" +
            "<p>Pickup for "+queueitem.num_total +" requested at " + queueitem.location_name +
            " headed to " + queueitem.destination_name +
            "</p>" +
            "</div>";
        return ret;
    }

    this.setStatus = function(status) {
        let oldstatus = self.status;
        self.status = status;
        switch (status) {
            case STATUS_ENABLED:
                // debugger;
                $("#"+self.dispatch_queue_tray_id).show();
                // $("#dispatch_overlay").show();
                break;
            case STATUS_DISABLED:
                $("#"+self.dispatch_queue_tray_id).hide();
                $("#dispatch_public_name_modal").modal('hide');
                $("#dispatch_assignment_modal").modal('hide');
                $("#dispatch_overlay").hide();
                self.overlay_visible = false;
                break;
            default:
                $("#"+self.dispatch_queue_tray_id).hide();
                $("#dispatch_public_name_modal").modal('hide');
                $("#dispatch_assignment_modal").modal('hide');
                $("#dispatch_overlay").hide();
                self.overlay_visible = false;
                break;
        }
        if((self.status===STATUS_ENABLED) && (oldstatus===STATUS_DISABLED)) {
            console.log("Status changed. querying settings and rendering");
            self.querySettings();

        }
    }

    this.checkForLogin = function() {

        if(!this.frame_loaded) {
            self.setStatus(STATUS_DISABLED);
            return;
        }

        //If no login then resume normal operation... I guess...
        //Check for form.. if it's there do a teardown
        if(self.gps_frame_document.getElementById(self.gps_tracker_login_id)!=null) {
            self.setStatus(STATUS_DISABLED);
            //TODO: erase the session cookie as well...
            //Also:
        }
        else {
            //No login form visible, so let's validate our session
            //But first, let's see if the main selector is there
            let sel = $("#"+self.gps_tracker_frame_id).contents().find(self.gps_tracker_loggedin_selector);
            if(sel.length>0) {
                self.setStatus(STATUS_ENABLED);
            }
            else {
                self.setStatus(STATUS_DISABLED);
            }
        }
    }

    this.queryDispatchQueue = function() {
        console.log("queryDispatchQueue");
        if(self.status===STATUS_DISABLED) { return; }
        $.ajax({
            url: self.api_endpoint + '/dispatch/queue?sessionid=' + this.getCookie("JSESSIONID"),
            processData: false,
            contentType: false,
            dataType: 'json',
            type: 'GET',
            beforeSend: function() {

            },
            error: function(err) {
                console.log(err);
                self.createAlert("Error", "Failed to query dispatch queue.", "danger");
            },
            success: function(resp) {
                self.renderQueue(resp.data);
            }
        });

    }

    this.getDispatchQueueItem = function(reference) {
        console.log("getDispatchQueueItem");
        if(self.status===STATUS_DISABLED) { return; }
        $.ajax({
            url: self.api_endpoint + '/dispatch/' + reference + "/?sessionid=" + this.getCookie("JSESSIONID"),
            processData: false,
            contentType: false,
            dataType: 'json',
            type: 'GET',
            beforeSend: function() {

            },
            error: function(err) {
                console.log(err);
                self.createAlert("Error", "Failed to retrieve queue item", "danger");
            },
            success: function(resp) {
                self.renderDispatchAssignment(resp.data);
                $("#dispatch_assignment_modal").modal();
            }
        });
    }

    this.removeDispatchQueueItem = function(reference) {
        console.log("removeDispatchQueueItem");
        if(self.status===STATUS_DISABLED) { return; }
        $.ajax({
            url: self.api_endpoint + '/dispatch/' + reference + "/remove/?sessionid=" + this.getCookie("JSESSIONID"),
            processData: false,
            contentType: false,
            dataType: 'json',
            type: 'GET',
            beforeSend: function() {

            },
            error: function(err) {
                console.log(err);
                self.createAlert("Error", "Failed to remove queue item", "danger");
            },
            success: function(resp) {
                self.createAlert("Success", "Successfully removed queue item", "success");
                $('#dispatch_assignment_modal').modal('hide');
                //Refresh the queue
                self.queryDispatchQueue();
            }
        });
    }

    this.deleteDispatchQueueItem = function(reference) {
        if(self.status===STATUS_DISABLED) { return; }
        $.ajax({
            url: self.api_endpoint + '/dispatch/' + reference + "/?sessionid=" + this.getCookie("JSESSIONID"),
            processData: false,
            contentType: false,
            dataType: 'json',
            type: 'DELETE',
            beforeSend: function() {

            },
            error: function(err) {
                console.log(err);
                self.createAlert("Error", "Failed to delete queue item", "danger");
            },
            success: function(resp) {
                self.current_queueitem_reference = "";
                $('#dispatch_assignment_modal').modal('hide');
                self.createAlert("Success", "Successfully deleted queue item", "success");
                self.queryDispatchQueue();
            }
        });
    }

    this.renameDevice = function(id, name) {
        console.log("renameDevice");
        if(self.status===STATUS_DISABLED) { return; }
        let formData = self.sessionFormData();
        formData.append("public_name", name)
            $.ajax({
                url: self.api_endpoint + '/dispatch/devices/' + id + "/rename/?sessionid=" + this.getCookie("JSESSIONID"),
                data: formData,
                processData: false,
                contentType: false,
                dataType: 'json',
                enctype: 'multipart/form-data',
                type: 'POST',
            beforeSend: function() {

            },
            error: function(err) {
                console.log(err);
                self.createAlert("Error", "Failed to rename vehicle", "danger");
            },
            success: function(resp) {
                $("#dispatch_public_name_modal").modal('hide');
                self.createAlert("Success", "Successfully renamed", "success");
                self.queryDevices();
            }
        });
    }

    this.renderDispatchAssignment = function(data) {
        console.log("renderDispatchAssignment");
        self.queryDevices();
        let utcDate = data.created_at;
        let localDate = new Date(utcDate);
        let localNotes = (data.notes==null)?"&nbsp;":data.notes;
        $('.modal-title').text("Queue Ref. "+data.reference);

        $(".assign-queueitem").show();
        $(".reassign-queueitem").hide();

        if((data.state==2)||(data.state==3)) {
            $(".assign-queueitem").hide();
            $(".reassign-queueitem").show();
        }

        $('.modal-body-text').empty();
        let modalBody = "<div class=\"container-fluid\">\n";

        modalBody +=  "  <div class=\"row\"><div class=\"col\">";
        modalBody += "<p class='dispatch_assignment_date'><label>Date:</label> " + localDate.toLocaleString() + "<br>";
        modalBody += "</div></div>";

        modalBody += "<div class=\"row\"><div class=\"col-md-6\">";
        modalBody += "<p><label>Reference:</label> " + data.reference + "<br>";
        modalBody += "<label>Guest:</label> " + data.guest_name + "<br>";
        modalBody += "<label>Start:</label> " + data.location_name + "<br>";
        modalBody += "<label>Destination:</label> " + data.destination_name + "<br>";
        modalBody += "<label>On Dock:</label> " + data.on_dock + "</p>";
        modalBody += "</div>";
        modalBody += "<div class=\"col-md-6\">";
        modalBody += "<p><label>Adults:</label> " + data.num_adults + "<br>";
        modalBody += "<label>Children:</label> " + data.num_children + "<br>";
        modalBody += "<label>Total Passengers:</label> " + data.num_total + "<br>";
        modalBody += "<label>Phone:</label> " + data.phone + "<br>";
        modalBody += "</p>";
        modalBody += "</div></div>";

        modalBody += "<div class=\"row\">";
        modalBody += "<div class=\"col-md-2\"><label>Notes:</label></div>";
        modalBody += "<div class=\"col-md-10\">" + localNotes;
        modalBody += "</div></div>" ;

        for(let i=0;i<data.assignments.length;i++) {

            let utcDate = data.assignments[i].created_at;
            let localDate = new Date(utcDate);

            modalBody += "<div class=\"row assignment_history\">";
            modalBody += "<div class=\"col-md-3\"><label>" + localDate.toLocaleString() + "</label></div>";
            modalBody += "<div class=\"col-md-9\">Assigned " + data.assignments[i].ferry_name + " arriving " + data.assignments[i].eta_desc;
            modalBody += "</div></div>" ;
        }

        modalBody += "</div>";
        $(modalBody).appendTo($('.modal-body-text'));
    }

    this.renderSettings = function() {
        console.log("renderSettings");
        if(self.customer_settings!=null) {
            self.setSystemStatus(self.customer_settings.system_status);
            $(".eta_list").empty();
            for(let i = 0;i < self.customer_settings.settings.eta_descriptions.length;i++) {
                let etadesc = "<option value='"+self.customer_settings.settings.eta_descriptions[i].text+"'>ETA: "+self.customer_settings.settings.eta_descriptions[i].text+"</option>";
                $(etadesc).appendTo($(".eta_list"));
            }
        }
    }

    this.setSystemStatus = function (status) {
        self.system_status = status;
        $(".settings_dot").removeClass("on");
        $(".settings_dot").removeClass("turnoff");
        $("#system_status_list").val(status);
        if(status==1) {
            $(".settings_dot").addClass("on");
            $(".settings_dot").addClass("turnoff");
        }
    }

    this.querySettings = function() {
        if(self.status===STATUS_DISABLED) { return; }
        $.ajax({
            url: self.api_endpoint + '/customer/settings/?sessionid=' + this.getCookie("JSESSIONID"),
            processData: false,
            contentType: false,
            dataType: 'json',
            type: 'GET',
            beforeSend: function() {

            },
            error: function(err) {
                console.log(err);
                self.createAlert("Error", "Failed to retrieve settings", "danger");
            },
            success: function(resp) {
                self.customer_settings = resp.data;
                self.render();
                self.renderSettings();
                self.queryDispatchQueue();
            }
        });
    }

    this.queryDevices = function() {
        console.log("QueryDevices");
        if(self.status===STATUS_DISABLED) { return; }
        let formData = self.sessionFormData();
        $.ajax({
            url: self.api_endpoint + '/dispatch/devices',
            data: formData,
            processData: false,
            contentType: false,
            dataType: 'json',
            enctype: 'multipart/form-data',
            type: 'POST',
            beforeSend: function() {

            },
            error: function(err) {
                console.log(err);
                self.createAlert("Error", "Failed to query devices", "danger");
            },
            success: function(resp) {
                console.log("Render devices");
                self.renderDevices(resp);
            }
        });

    }

    this.assignDevice = function(reference, id, eta) {
        if(self.status===STATUS_DISABLED) { return; }
        //var formData = new FormData();
        let formData = self.sessionFormData();
        //formData.append('sessionid', this.getCookie("JSESSIONID"));
        formData.append('deviceid', id);
        formData.append('eta', eta);
        $.ajax({
            url: self.api_endpoint + '/dispatch/' + reference,
            data: formData,
            processData: false,
            contentType: false,
            dataType: 'json',
            enctype: 'multipart/form-data',
            type: 'POST',
            beforeSend: function() {

            },
            error: function(err) {
                console.log(err);
                self.createAlert("Error", "Unable to assign queue item "+ reference, "danger");

                // self.createAlert("Error", "Error assigning queue item.", "danger");
                self.queryDispatchQueue();
            },
            success: function(resp) {
                self.createAlert("Success", "Successfully assigned queue item "+ reference, "success");
                self.queryDispatchQueue();
            }
        });

    }

    this.sessionFormData = function() {
        let formData = new FormData();
        formData.append('sessionid', this.getCookie("JSESSIONID"));
        return formData;
    }

    this.createAlert = function(title, text, type) {
        let alert = "<div class=\"alert alert-"+ type + " alert-dismissible show\" role=\"alert\">";
        alert += "<strong>" + title + "</strong><br>" + text;
        alert += "<button type=\"button\" class=\"close\" data-dismiss=\"alert\" aria-label=\"Close\">";
        alert += "<span aria-hidden=\"true\">&times;</span>";
        alert += "</button>";
        alert += "</div>";

        $(alert).appendTo($("#dispatch_alerts"));
         setTimeout(function() {

            $(".alert").slideUp(400, "swing", function() {
                $(this).remove();
            })

        }, 5000);

    }

    this.querySystemStatus = function() {
        if(self.status===STATUS_DISABLED) { return; }

        let formData = self.sessionFormData();

        $.ajax({
            url: self.api_endpoint + '/customer/status/get',
            data: formData,
            processData: false,
            contentType: false,
            dataType: 'json',
            enctype: 'multipart/form-data',
            type: 'POST',
            beforeSend: function() {

            },
            error: function(err) {
                console.log(err);
                self.createAlert("Error", "Unable to retrieve system status", "danger");
            },
            success: function(resp) {
                self.setSystemStatus(resp.data.system_status);
            }
        });
    }

    this.updateSystemStatus = function(status) {
        if(self.status===STATUS_DISABLED) { return; }

        let formData = self.sessionFormData();
        formData.append("system_status", parseInt(status));

        $.ajax({
            url: self.api_endpoint + '/customer/status/set',
            data: formData,
            processData: false,
            contentType: false,
            dataType: 'json',
            enctype: 'multipart/form-data',
            type: 'POST',
            beforeSend: function() {

            },
            error: function(err) {
                console.log(err);
                self.createAlert("Error", "Unable to update system status", "danger");
            },
            success: function(resp) {
                self.createAlert("Success", "Successfully updated system status", "success");
                self.querySystemStatus();
            }
        });
    }

    this.checkSession = function() {
        if(self.status===STATUS_DISABLED) { return; }

        //TODO: Check the session to make sure it's valid...
        //If valid we carry on
        //If no, kill the session id cookie and teardown
        let formData = self.sessionFormData();

        $.ajax({
            url: self.api_endpoint + '/session/validate',
            data: formData,
            processData: false,
            contentType: false,
            dataType: 'json',
            enctype: 'multipart/form-data',
            type: 'POST',
            beforeSend: function() {

            },
            error: function(err) {
                console.log("check session error");
                //TODO: on failure disable app
            },
            success: function(resp) {
                //TODO: on no login disable app
                console.log("success");
            }
        });

    }

    this.setSession = function(session_id) {
        //If the session is blank then we tear down the app...
        if(session_id==="") {
            this.session.valid = false;
            this.session.id = "";
            self.setStatus(STATUS_DISABLED);
        }
    }

    this.getCookie = function(cookie_name) {
        let name = cookie_name + "=";
        let decodedCookie = decodeURIComponent(document.cookie);
        let ca = decodedCookie.split(';');
        for(let i = 0; i <ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) === ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) === 0) {
                return c.substring(name.length, c.length);
            }
        }
        return "";
    }

    this.init();
}
